export class Account {
    customer!: Customer;
    address_default!: Address;
    addresses!: Array<Address>;
    salesperson!: SalesPerson;
}

export class Customer {
    id!: number;
    no!: string;
    name!: string;
    address!: string;
    zipcode!: string;
    city!: string;
}

export class Address {
    id!: number;
    code!: string;
    address!: string;
    zipcode?: string;
    city!: string;
}

export class SalesPerson {
    id!: number;
    name!: string;
    email!: string;
    phone!: string;
}
