import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "account-container",
  class: "container"
}
const _hoisted_2 = { class: "row justify-content-between align-items-stretch" }
const _hoisted_3 = {
  id: "top-section",
  class: "col"
}
const _hoisted_4 = { class: "info-card" }
const _hoisted_5 = { class: "section" }
const _hoisted_6 = { class: "text-start" }
const _hoisted_7 = { class: "section" }
const _hoisted_8 = { class: "col d-flex flex-wrap" }
const _hoisted_9 = { class: "row w-100" }
const _hoisted_10 = { class: "col mb-3" }
const _hoisted_11 = { class: "info-card" }
const _hoisted_12 = { class: "form" }
const _hoisted_13 = { class: "row w-100" }
const _hoisted_14 = { class: "col mt-2" }
const _hoisted_15 = { class: "info-card card-gray" }
const _hoisted_16 = { class: "in-front" }
const _hoisted_17 = { class: "list-title" }
const _hoisted_18 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddressItem = _resolveComponent("AddressItem")!
  const _component_EyeIcon = _resolveComponent("EyeIcon")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[8] || (_cache[8] = _createElementVNode("h1", { class: "text-start" }, " Accountoverzicht ", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h2", _hoisted_6, _toDisplayString($setup.user?.name), 1),
            _createVNode(_component_AddressItem, {
              title: 'Klantnummer ' + $setup.user['customer-no'],
              name: $setup.user.email
            }, null, 8, ["title", "name"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "text-start" }, " Adressen ", -1)),
            _createVNode(_component_AddressItem, {
              title: "Standaard bezorgadres",
              name: $setup.account.customer?.name,
              street: $setup.account.address_default?.address,
              postal: $setup.account.address_default?.zipcode + ' ' + $setup.account.address_default?.city
            }, null, 8, ["name", "street", "postal"]),
            _createVNode(_component_AddressItem, {
              title: "Factuuradres",
              name: $setup.account.customer?.name,
              street: $setup.account.customer?.address,
              postal: $setup.account.customer?.zipcode + ' ' + $setup.account.customer?.city
            }, null, 8, ["name", "street", "postal"])
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "section" }, [
            _createElementVNode("p", { class: "text-start contact-info" }, [
              _createTextVNode(" Wilt u uw gegevens wijzigen? "),
              _createElementVNode("br"),
              _createTextVNode(" Neem dan contact met ons op via "),
              _createElementVNode("a", {
                href: "tel:0252686741",
                class: "red"
              }, " 0252 68 67 41 "),
              _createElementVNode("br"),
              _createTextVNode(" of stuur een e-mail naar "),
              _createElementVNode("a", {
                href: "mailto:bestellingen@kwastwijnkopers.nl",
                class: "red underline"
              }, " bestellingen@kwastwijnkopers.nl ")
            ])
          ], -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _cache[6] || (_cache[6] = _createElementVNode("h2", { class: "text-start" }, " Wachtwoord wijzigen ", -1)),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_TextInput, {
                  modelValue: $setup.vv.password_old.$model,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.vv.password_old.$model) = $event)),
                  hasErrors: $setup.vv.password_old.$error || $setup.serverValidation.password_old,
                  error: $setup.serverValidation.password_old,
                  label: "Huidig wachtwoord",
                  type: $setup.passwordOldType,
                  onIconRightClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.showPassword('oldPass')))
                }, {
                  "icon-right": _withCtx(() => [
                    _createVNode(_component_EyeIcon, { class: "icon" })
                  ]),
                  _: 1
                }, 8, ["modelValue", "hasErrors", "error", "type"]),
                _createVNode(_component_TextInput, {
                  modelValue: $setup.vv.password_new.$model,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.vv.password_new.$model) = $event)),
                  hasErrors: $setup.vv.password_new.$error || $setup.serverValidation.password_new,
                  error: $setup.serverValidation.password_new,
                  label: "Nieuw wachtwoord",
                  type: $setup.passwordNewType,
                  onIconRightClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.showPassword('newPass')))
                }, {
                  "icon-right": _withCtx(() => [
                    _createVNode(_component_EyeIcon, { class: "icon" })
                  ]),
                  _: 1
                }, 8, ["modelValue", "hasErrors", "error", "type"]),
                _createVNode(_component_Button, {
                  type: "button",
                  design: "primary",
                  text: "Wachtwoord wijzigen",
                  onButtonClick: $setup.changePassword
                }, null, 8, ["onButtonClick"])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _cache[7] || (_cache[7] = _createElementVNode("h2", { class: "text-start" }, [
                  _createTextVNode(" Advies nodig? "),
                  _createElementVNode("br"),
                  _createTextVNode(" Ik sta voor u klaar. ")
                ], -1)),
                _createElementVNode("ul", null, [
                  _createElementVNode("li", _hoisted_17, _toDisplayString($setup.account.salesperson?.name), 1),
                  _createElementVNode("li", null, _toDisplayString($setup.account.salesperson?.email), 1),
                  _createElementVNode("li", null, _toDisplayString($setup.account.salesperson?.phone), 1)
                ])
              ]),
              ($setup.account.salesperson)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "position-absolute",
                    src: require('@/assets/images/' + $setup.account.salesperson?.email + '.png'),
                    alt: "Person"
                  }, null, 8, _hoisted_18))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ])
  ]))
}