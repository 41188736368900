
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ref } from 'vue';
import { useStore } from 'vuex';
import EyeIcon from '@/assets/icons/eye-outline.svg';
import AddressItem from '@/shared/components/AddressItem.vue';
import Button from '@/shared/components/Button.vue';
import TextInput from '@/shared/components/form/TextInput.vue';
import { Account } from '@/shared/models/Account';

export default {
    components: {
        TextInput, Button, AddressItem, EyeIcon,
    },
    setup() {
        const store = useStore();
        const user = store.getters.authUser;
        const account = ref(new Account());
        const passwordOldType = ref('password');
        const passwordNewType = ref('password');

        function getAccountInfo() {
            store.commit('LOADING_START');
            store.dispatch('AUTH_INFO').then((res) => {
                account.value = res.data.data;
                store.commit('LOADING_STOP');
            });
        }

        getAccountInfo();

        const form = ref({
            password_old: '',
            password_new: '',
        });

        const rules = {
            password_old: {
                required,
            },
            password_new: {
                required,
            },
        };

        const vv = useVuelidate(rules, form);

        function showPassword(pass: any) {
            if (pass === 'newPass') {
                if (passwordNewType.value === 'password') {
                    passwordNewType.value = 'text';
                } else {
                    passwordNewType.value = 'password';
                }
            } else if (passwordOldType.value === 'password') {
                passwordOldType.value = 'text';
            } else {
                passwordOldType.value = 'password';
            }
        }

        const serverValidation: any = ref({});

        function clearServerErrors() {
            serverValidation.value = {};
        }

        function resetValues() {
            form.value.password_old = '';
            form.value.password_new = '';
        }

        function changePassword() {
            vv.value.$touch();
            clearServerErrors();
            if (vv.value.$invalid) {
                return;
            }

            const formData = {
                password_old: form.value.password_old,
                password_new: form.value.password_new,
            };
            store.dispatch('AUTH_CHANGE_PASSWORD', formData).then(() => {
                resetValues();
                // TODO: toast with success
            }).catch((err) => {
                if (err.response.data) {
                    const error = err.response.data;
                    for (const [key, value] of Object.entries(error.errors)) {
                        if (key === 'password_old') {
                            serverValidation.value.password_old = 'Wachtwoord is onjuist';
                        }

                        if (key === 'password_new') {
                            // TODO: Change to proper validation message
                            serverValidation.value.password_new = 'Wachtwoord niet sterk genoeg';
                        }
                    }
                }
            });
        }

        return {
            store,
            form,
            showPassword,
            changePassword,
            user,
            account,
            vv,
            serverValidation,
            passwordOldType,
            passwordNewType,
        };
    },
};
